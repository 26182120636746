import React, { useEffect } from "react";
import "./App.css";

const App = () => {
  useEffect(() => {
    // Function to rotate a cell
    const rotateCell = (cell, duration, delay) => {
      cell.style.transition = `transform ${duration}s linear ${delay}s`;
      cell.style.transform = "rotate(360deg)";
    };

    // Rotate each cell with different speeds and delays
    rotateCell(document.getElementById("cell-0-4"), 2, 0);
    rotateCell(document.getElementById("cell-1-2"), 1.5, 0.5);
    rotateCell(document.getElementById("cell-1-3"), 1, 1);
    rotateCell(document.getElementById("cell-1-5"), 0.5, 1.5);

    rotateCell(document.getElementById("cell-2-2"), 1, 0);
    rotateCell(document.getElementById("cell-2-3"), 0.5, 0.5);
    rotateCell(document.getElementById("cell-2-4"), 1.5, 1);

    rotateCell(document.getElementById("cell-3-1"), 2, 1.5);
    rotateCell(document.getElementById("cell-3-2"), 1.5, 0);
    rotateCell(document.getElementById("cell-3-3"), 1, 0.5);
    rotateCell(document.getElementById("cell-3-4"), 0.5, 1);

    rotateCell(document.getElementById("cell-4-1"), 1, 1.5);
    rotateCell(document.getElementById("cell-4-2"), 0.5, 0);
    rotateCell(document.getElementById("cell-4-3"), 2, 0);
    rotateCell(document.getElementById("cell-4-4"), 1.5, 0.5);

    rotateCell(document.getElementById("cell-5-0"), 1.2, 0.3);
    rotateCell(document.getElementById("cell-5-1"), 0.5, 1);
    rotateCell(document.getElementById("cell-5-2"), 1, 1);
    rotateCell(document.getElementById("cell-5-3"), 1, 0.5);
    rotateCell(document.getElementById("cell-5-4"), 1.2, 1);
    rotateCell(document.getElementById("cell-5-5"), 0.5, 0.5);
    rotateCell(document.getElementById("cell-5-6"), 1, 0.2);
    
    rotateCell(document.getElementById("cell-6-1"), 1, 1);
    rotateCell(document.getElementById("cell-6-2"), 0.7, 1);
    rotateCell(document.getElementById("cell-6-3"), 0.9, 1);
    rotateCell(document.getElementById("cell-7-1"), 1, 0.8);
    rotateCell(document.getElementById("cell-7-2"), 1, 1.1);
    rotateCell(document.getElementById("cell-7-3"), 0.6, 1);
    rotateCell(document.getElementById("cell-8-1"), 1, 1.1);

    // Function to animate the text after cell animations
    const animateText = () => {
      const paperSlabsText = document.getElementById("paper-slabs");

      // Fade in and grow the text
      paperSlabsText.style.opacity = 1;
      paperSlabsText.style.fontSize = "1.5em"; // Adjust as needed
    };

    // Get the last rotating cell
    const lastCell = document.getElementById("cell-3-1");

    // Listen for the 'transitionend' event on the last cell
    lastCell.addEventListener("transitionend", () => {
      // Trigger the text animation after the last cell animation is complete
      animateText();
    });

    // Cleanup event listener on component unmount
    return () => {
      lastCell.removeEventListener("transitionend", animateText);
    };
  }, []); // Empty dependency array means the effect runs once after initial render

  return (
    <div className="App">
      <div className="container">
        <table className="rotating-table">
          <tbody>
            <tr>
              <td id="cell-0-0"></td>
              <td id="cell-0-1"></td>
              <td id="cell-0-2"></td>
              <td id="cell-0-3"></td>
              <td id="cell-0-4"></td>
              <td id="cell-0-5"></td>
              <td id="cell-0-6"></td>
            </tr>
            <tr>
              <td id="cell-1-0"></td>
              <td id="cell-1-1"></td>
              <td id="cell-1-2"></td>
              <td id="cell-1-3"></td>
              <td id="cell-1-4"></td>
              <td id="cell-1-5"></td>
              <td id="cell-1-6"></td>
            </tr>
            <tr>
              <td id="cell-2-0"></td>
              <td id="cell-2-1"></td>
              <td id="cell-2-2"></td>
              <td id="cell-2-3"></td>
              <td id="cell-2-4"></td>
              <td id="cell-2-5"></td>
              <td id="cell-2-6"></td>
            </tr>
            <tr id="row-3">
              <td id="cell-3-0"></td>
              <td id="cell-3-1"></td>
              <td id="cell-3-2"></td>
              <td id="cell-3-3"></td>
              <td id="cell-3-4"></td>
              <td id="cell-3-5"></td>
              <td id="cell-3-6"></td>
            </tr>
            <tr id="row-4">
              <td id="cell-4-0"></td>
              <td id="cell-4-1"></td>
              <td id="cell-4-2"></td>
              <td id="cell-4-3"></td>
              <td id="cell-4-4"></td>
              <td id="cell-4-5"></td>
              <td id="cell-4-6"></td>
            </tr>
            <tr id="row-5">
              <td id="cell-5-0"></td>
              <td id="cell-5-1"></td>
              <td id="cell-5-2"></td>
              <td id="cell-5-3"></td>
              <td id="cell-5-4"></td>
              <td id="cell-5-5"></td>
              <td id="cell-5-6"></td>
            </tr>
            <tr id="row-6">
              <td id="cell-6-0"></td>
              <td id="cell-6-1"></td>
              <td id="cell-6-2"></td>
              <td id="cell-6-3"></td>
              <td id="cell-6-4"></td>
              <td id="cell-6-5"></td>
              <td id="cell-6-6"></td>
            </tr>
            <tr id="row-7">
              <td id="cell-7-0"></td>
              <td id="cell-7-1"></td>
              <td id="cell-7-2"></td>
              <td id="cell-7-3"></td>
              <td id="cell-7-4"></td>
              <td id="cell-7-5"></td>
              <td id="cell-7-6"></td>
            </tr>
            <tr id="row-8">
              <td id="cell-8-0"></td>
              <td id="cell-8-1"></td>
              <td id="cell-8-2"></td>
              <td id="cell-8-3"></td>
              <td id="cell-8-4"></td>
              <td id="cell-8-5"></td>
              <td id="cell-8-6"></td>
            </tr>
          </tbody>
        </table>
        <div id="paper-slabs">
          <div className="first">paper</div>
          <div className="second">slabs</div>
        </div>
      </div>
    </div>
  );
};

export default App;
